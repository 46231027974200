.HelpCenter-Container{
    width:100%;
    height: 100%;
}
.HelpCenter-Container .HelpCenter-Body-Container{
    display: flex;
    justify-content: center;
    padding:50px;
    background-color: #e5e5e4;
}
.HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body{
    width:70%;
    height: 100%;
}
.HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Header{
    padding:10px;
    padding-left: 20px;
    background: linear-gradient(0deg, rgba(229,233,233,1) 0%, rgba(238,238,238,1) 46%, rgba(252,252,252,1) 100%);
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: 1px 1px 2px 1px gray;
}
.HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section{
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   margin-top: 20px;
}
.HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Sidebar{
    width: 25%;
    border: solid 1px lightgray;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 1px 2px 2px lightgray;
 }
 .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Sidebar .Header{
    padding:10px;
    background :linear-gradient(0deg, rgba(150, 3, 3, 0.9) 0%, rgb(150, 3, 3, 0.9) 30%, rgb(187, 116, 116) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
 }
 .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Sidebar .body{
    padding:10px; 
 }
 .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Sidebar .body h4:hover{
   cursor: pointer;
   background-color: #eeeeee;
}
 .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Main-Body{
    width: 70%;
    border: solid 1px lightgray;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 1px 2px 2px lightgray;
 }
 .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Main-Body .Header{
    padding:10px;
    background:linear-gradient(0deg, rgba(150, 3, 3, 0.9) 0%, rgb(150, 3, 3, 0.9) 30%, rgb(187, 116, 116) 100%);
    border-radius: 5px 5px 0px 0px;
 }
 .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Main-Body .body .search-area{
    padding:10px;
    background-color: #eeeeee;
    border-bottom: solid 1px lightgray;
 }
 .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Main-Body .body .question{
    padding:15px;
    overflow-y: auto;
 }
 .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Main-Body .body .question .collaps .panel-content{
   max-width:100%;
}
.HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Main-Body .body .question .collaps .panel-content > img
{
   width:100px;
}
 .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Main-Body .body .question .collaps .panel-content > p,h1,h2,h3,h4,h5,h6,span,img,strong{
   margin:0px;
}

@media(max-width:1024px)
{
   .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body{
      width:80%;
  }
}
@media(max-width:768px)
{
   .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body{
      width:100%;
  }
}
@media(max-width:576px)
{
   .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section{
      display: flex;
      flex-direction: column;
   }
   .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Sidebar{
      width: 100%;
      margin-bottom: 20px;
   }
   .HelpCenter-Container .HelpCenter-Body-Container .HelpCenter-Body .HelpCenter-Inner-Section .HelpCenter-Inner-Section-Main-Body{
      width: 100%;
   }
}
@media(max-width:375px)
{
   .HelpCenter-Container .HelpCenter-Body-Container{
      padding:20px;
  }
}